<template>
	<div>
		<div class="m-content" >
			<div class="row mt-5" >
				<div class="col-md-3 col-sm-3 col-lg-3">
					<div class="m-portlet m-portlet--bordered-semi m-portlet--rounded-force">
						<div class="m-portlet__body">
							<div class="m-widget17">
								<div class="m-widget17__visual m-widget17__visual--chart m-portlet-fit--top m-portlet-fit--sides border-bottom">
									<div class="m-widget17__chart" style="height:250px;">
										<div class="col-md-12 text-center mt-3">
											<div v-if="company.logo" style="max-width:100%">
												<img  alt="" :src="company.logo" height="60px" />
											</div>	
											<h4 v-else >{{ company.name }}</h4>
										</div>
									</div>
								</div>			
								<div class="m-widget17__stats">
									<div class="m-widget17__items m-widget17__items-col1">
										<div class="m-widget17__item text-center pt-3 h-100">
											<div class="mb-3 mt-2">
												<h2>{{ this.services && this.services.data ? this.services.data.length : 0 }}</h2>
											</div>
											<div class="widget_desc pb-2" style="font-size:13px">
												Serviços
											</div>
										</div>
									</div>
									<div class="m-widget17__items m-widget17__items-col2">
										<div class="m-widget17__item text-center pt-3 h-100">
											<div class="mb-3 mt-2">
												<h2>{{ this.usersByCompany.length }}</h2>
											</div>
											<div class="widget_desc pb-2" style="font-size:13px">
												Usuários
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="this.company.location_address" class="mt-5">
								<h5>Endereço</h5>
								<div v-if="this.company.location_address">{{ this.company.location_address }}, </div>
								<span v-if="this.company.location_number">{{ this.company.location_number }} - </span>
								<span v-if="this.company.location_additional_info">{{ this.company.location_additional_info }} - </span>
								<span v-if="this.company.location_city">{{ this.company.location_city }} - </span>
								<span v-if="this.company.location_state">{{ this.company.location_state }}</span>
							</div>
							<div v-if="this.company.contact_name" class="mt-5">
								<h5>Contato</h5>
								<div>{{ this.company.contact_name }}</div>
								<div>{{ this.company.contact_email }}</div>
								<div>{{ this.company.contact_phone }}</div>
							</div>
							
						</div>
					</div>
				</div>
				<div class="col-sm-9 col-md-9 col-lg-9">
					<div class="m-portlet m-portlet--tabs">
						<div class="m-portlet__head">
							<div class="m-portlet__head-caption">
								<div class="m-portlet__head-title">
									<h3 class="m-portlet__head-text">
										{{ cardInfoTitle }}
									</h3>
								</div>
							</div>
							<div class="m-portlet__head-tools">
								<ul class="nav nav-tabs m-tabs m-tabs-line   m-tabs-line--right m-tabs-line-danger" role="tablist">
									<li class="nav-item m-tabs__item">
										<a @click="() => cardInfoTitle = 'Meus serviços'" class="nav-link m-tabs__link active show" data-toggle="tab" href="#services" role="tab" aria-selected="false">
											Serviços
										</a>
									</li>
									<li class="nav-item m-tabs__item">
										<a @click="() => cardInfoTitle = 'Usuários'" class="nav-link m-tabs__link" data-toggle="tab" href="#users" role="tab" aria-selected="false">
											Usuários
										</a>
									</li>
									<li class="nav-item m-tabs__item">
										<a @click="() => cardInfoTitle = 'Integração'" class="nav-link m-tabs__link " data-toggle="tab" href="#integration" role="tab" aria-selected="true">
											Integração
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div class="m-portlet__body">
							<div class="tab-content">
								<div class="tab-pane active show" id="services">
									<u-table :columns="columns">
										<tr v-if="isLoading">
											<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
												<div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
											</td>
										</tr>
										<tr v-if="!$authorization.can('services') || servicesNotFound || (!isLoading && !services.data.length)">
											<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
												Nenhum serviço cadastrado
											</td>
										</tr>
										<tr v-if="services.data.length" v-for="(service, index) in services.data" :key="index">
											<td class="pt-4 pb-4">{{ service.name  }}</td>
											<td class="pt-4 pb-4">{{ $helper.convertDatePtBr(service.updated_at) }}</td>
											<td class="pt-4 pb-4">
												<span :class="`m-badge m-badge--${serviceupport.registerBadge(service.status_register).color} m-badge--wide`">
													{{ serviceupport.registerBadge(service.status_register).name }}
												</span>
											</td>
											<td class="pt-4 pb-4">
												<span :class="`m-badge m-badge--${serviceupport.statusBadge(service.status).color} m-badge--wide`">
													{{ serviceupport.statusBadge(service.status).name }}
												</span>
											</td>
											<td class="pt-4 pb-4">
												<router-link :to="{name: 'service.basic-info', params: { id: service.id } }" title="Visualizar">
													<i class="fa flaticon-cogwheel-2"></i>
												</router-link>
											</td>
										</tr>
									</u-table>
								</div>
								<div class="tab-pane" id="users">
									<u-table :columns="userColumns">
										<tr v-if="isLoading">
											<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
												<div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
											</td>
										</tr> 
										<tr v-if="!isLoading && !usersByCompany.length">
											<td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
												Nenhum usuário cadastrado
											</td>
										</tr>
										<tr v-if="usersByCompany.length" v-for="(user, userIndex) in usersByCompany" :key="userIndex">
											<td class="pt-4 pb-4">{{ `${user.first_name} ${user.last_name}`  }}</td>
											<td class="pt-4 pb-4">{{ user.email  }}</td>
											<td class="pt-4 pb-4">{{ $helper.convertDatePtBr(user.created_at)  }}</td>
											<td class="pt-4 pb-4">
												<span :class="`m-badge m-badge--${serviceupport.registerBadge(user.status).color} m-badge--wide`">
													{{ serviceupport.registerBadge(user.status).name }}
												</span>
											</td>
										</tr>
									</u-table>
								</div>
								<div class="tab-pane" id="integration">
									<div v-if="!companyIntegrations.length" class="text-center">Nenhuma integração cadastrada</div>
									<div 
										v-if="companyIntegrations.length"
										v-for="(companyIntegration, companyIntegrationIndex) in companyIntegrations"
										:key="companyIntegrationIndex"
									 	class="m-portlet m-portlet--mobile m-portlet--body-progress-"
									>
										<div class="m-portlet__head">
											<div class="m-portlet__head-caption">
												<div class="m-portlet__head-title">
													<h3 class="m-portlet__head-text">
														{{ serviceupport.companyIntegrationEnvironment(companyIntegration.environment) }}
													</h3>
												</div>
											</div>
										</div>
										<div class="m-portlet__body">
											<div class="row">
												<div class="col-md-12">
													<h6>URL Datasync</h6>
													<div>{{ companyIntegration.datasync }}</div>
												</div>
												<div class="col-md-12 mt-5">
													<h6>URL MO</h6>
													<div>{{ companyIntegration.url_mo }}</div>
												</div>
											</div>

											<div class="mt-5">
											<h6>IP List</h6>
												<div 
													v-if="companyIntegration.ip_list" 
													v-for="(ipList, ipListIndex) in companyIntegration.ip_list" 
													:key="ipListIndex" 
												>
													{{ ipList }}
												</div>									
											</div>											
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
</div>
</template>

<script>
import UTable from '@/components/UTable';
import ServiceService from '../../service/services/ServiceService';
import ServiceSupport from '../../service/support';

export default {
  name: 'services',
  components: {
			UTable,
	},
  data() {
		return {
			isLoading: true,
		  isCreating: false,
		  serviceupport: ServiceSupport,
			service: {
			  name: '',
        company_id: null
      },
			serviceService: new ServiceService(),
			services: {},
			servicesNotFound: false,
			companiesContent: [],
			totalServices: 0,
			company: {},
			companyIntegrations: [],
			usersByCompany: [],
			page: 1,
			cardInfoTitle: 'Meus serviços',
      columns: [
        'Nome',
        'Última Atualização',
        'Cadastro',
        'Status',
        '',
			],
			userColumns: [
        'Nome',
        'E-mail',
				'Cadastrado em',
				'Status',
			],
		}
	},
	created() {
		if (this.$authorization.can('services')) {
			this.all();
		} else {
			this.isLoading = false;
		}
	},
	methods: {
		all() {
		  return this.serviceService.all(this.page, this.filter, this.$route.params.id)
			.then((response) => {
				this.services = response.data.services;
			  this.companiesContent = response.data.companiesContent;
				this.company = response.data.company,
				this.usersByCompany = response.data.usersByCompany
				this.companyIntegrations = response.data.companyIntegration
				this.isLoading = false;
			})
			.catch((error) => {
				this.servicesNotFound = true;
				this.isLoading = false;
			  console.log(error.response)
			})
		},
	}
}
</script>

<style scoped>
	.h-100 {
		height: '150px' !important;
	}
	.widget_desc {
		font-size: 11px !important;
	}
</style>
